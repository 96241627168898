import { createContext, useContext, useEffect, useState } from 'react'
import { useCollection } from "../hooks/useCollection";

export const LessonContext = createContext()

export function useLessonContext() {
  return useContext(LessonContext)
}

export const LessonContextProvider = ({ children }) => {

  const { documents: lessons } = useCollection("lessons");
  const [lessonState, setLessonState] = useState(null)
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLessonState(lessons)
    setLoading(false);
  }, [lessons]);

  return (
    <>
      {loading ? (
        <div className="text-2xl text-white">Loading...</div>
      ) : (
        //pass array of section titles and lessons for that particular course
        <LessonContext.Provider value={lessonState || []}>
          {children}
        </LessonContext.Provider>
      )}
    </>
  )
}