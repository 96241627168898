import { BsGithub } from 'react-icons/bs';
import { FcGoogle } from 'react-icons/fc';
import { auth } from "../firebase/config";

import { GoogleAuthProvider, GithubAuthProvider, signInWithRedirect } from "firebase/auth";


const AuthButtons = ({ category, disabled }) => {
  const provider = new GoogleAuthProvider();
  const githubProvider = new GithubAuthProvider();

  const handleGoogleSignIn = async () => {
    try {
      await signInWithRedirect(auth, provider);
    }
    catch (error) {
      console.log('error', error);
    }
  }

  const handleGiHubSignIn = async () => {
    try {
      await signInWithRedirect(auth, githubProvider);
    } catch (error) {
      console.log('error', error);
    }
  }

  return (
    <div className="block m-0 p-0">
      <p className="text-center text-gray-300 mb-2 mt-2">OR</p>
      <button
        disabled={disabled}
        onClick={handleGoogleSignIn}
        className={`mb-5 w-full flex items-center px-3 py-2 font-medium tracking-wide justify-center text-white capitalize transition-colors duration-200 transform bg-indigo-600 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring focus:ring-indigo-300 focus:ring-opacity-80 ${disabled && 'opacity-50 cursor-not-allowed'}`}
      >
        <FcGoogle className="text-xl" />
        <span className="mx-2">{category} With Google</span>
      </button>
      <button
        disabled={disabled}
        onClick={handleGiHubSignIn}
        className={`w-full flex items-center px-3 py-2 font-medium tracking-wide justify-center text-white capitalize transition-colors duration-200 transform bg-indigo-600 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring focus:ring-indigo-300 focus:ring-opacity-80 ${disabled && 'opacity-50 cursor-not-allowed'}`}
      >
        <BsGithub className="text-xl" />
        <span className="mx-2">{category} With Github</span>
      </button>
    </div>
  );
}

export default AuthButtons;