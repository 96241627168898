import { useEffect, useState } from 'react';
import { usePostHog } from 'posthog-js/react'
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import CourseList from '../components/CourseList'
import UserProgress from "../components/UserProgress";
import ChatBot from "../components/ChatBot";
import { useUserMetaDataContext } from "../hooks/useAuthContext";

export default function Dashboard() {
  const posthog = usePostHog()
  const { user, profileExists, submissions, authIsReady, userWalletAddress
  } = useUserMetaDataContext();
  const [courseList, setCourseList] = useState([]);
  const [isShown, setIsShown] = useState(false);

  const toggle = () => {
    setIsShown(!isShown);
    posthog.capture('ChatBot Clicked');
  };


  useEffect(() => {
    document.title = 'Dashboard - Cadena';
    window.analytics.page(`Dashboard - Cadena`);
    window.analytics.identify(`${user.uid}`, {
      email: `${user.email}`,
    });
    posthog.identify(`${user.uid}`)
    posthog.people.set({ email: `${user.email}` })

  }, [user]);

  useEffect(() => {
    if (submissions.length > 0) {
      setCourseList([...submissions]);
    }
    //console.log('courseList', courseList);
  }, [submissions.length, courseList.length])


  //console.log('courseList outisde', courseList);
  return (
    <div className="dashboard-page">
      <Header />
      <main className="">
        <div className="flex">
          <div className="hidden h-screen sticky top-0 lg:block">
            <div className="sidebar min-h-screen w-64">
              <Sidebar />
            </div>
          </div>
          <div className="grid md:grid-cols-2 md:gap-10 p-10">
            <UserProgress user={user} profileExists={profileExists} submissions={submissions} authIsReady={authIsReady} userWalletAddress={userWalletAddress} />
            <CourseList submissions={courseList} user={user} />
          </div>
          <div id="chat-bot">
            <button
              className="fixed right-5 bottom-5 transition hover:scale-110"
              onClick={toggle}
            >
              <img
                width={160}
                src="https://cadena.dev/wp-content/uploads/2023/09/cadena-chat.png"
                alt="CadenaChat"
              />
            </button>

            {isShown &&
              <div className="fixed right-5 bottom-28 transition-transform duration-500">
                <ChatBot width={"350px"} height={"500px"} />
              </div>
            }
          </div>
        </div>
      </main>
    </div>
  );
}