import { useEffect } from 'react';
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import WalletConnect from "../components/WalletConnect";

export default function MyCollection() {

  useEffect(() => {
    document.title = 'My NFT Collection - Cadena';
    window.analytics.page(`My NFT Collection - Cadena`);
  }, []);

  return (
    <div className="course-page">
      <Header />
      <main className="">
        <div className="flex">
          <div className="hidden h-screen sticky top-0 lg:block">
            <div className="sidebar min-h-screen w-64">
              <Sidebar />
            </div>
          </div>
          <div className="grid p-2 sm:p-10 w-full">
            <div className="grid content-center w-full max-w-none leading-6 rounded-lg shadow-sm border-2 border-dashed border-gray-600 p-5">
              <h2 className="text-center text-gray-600 text-4xl leading-normal">Complete the ETH 101 Course to Recieve an NFT Certificate</h2>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
