import React from 'react'
import { Link } from 'react-router-dom'
import { useLogout } from "../hooks/useLogout";
import { Menu } from '@headlessui/react'

export default function Navbar() {

  const { logout } = useLogout()

  return (
    <nav className="flex items-center justify-center">
      <Menu as="div" className="relative inline-block">
        {/* {Dropdown toggle button} */}
        <Menu.Button className="relative z-10 block p-2 border border-transparent rounded-md text-white bg-gray-700 focus:outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
          </svg>
        </Menu.Button>

        {/*Dropdown menu*/}
        <Menu.Items className="absolute right-0 z-20 w-48 py-2 mt-2 rounded-md shadow-xl backdrop-filter backdrop-brightness-200 bg-gray-800">
          <Menu.Item><Link to="/dashboard" className="block px-4 py-3 text-sm capitalize transition-colors duration-200 transform text-gray-300 hover:bg-gray-700 hover:text-white"> Dashboard </Link></Menu.Item>
          <Menu.Item><Link to="/ask-cadena" className="block px-4 py-3 text-sm capitalize transition-colors duration-200 transform text-gray-300 hover:bg-gray-700 hover:text-white"> AI Tutor </Link></Menu.Item>

          <Menu.Item><Link to="/profile" className="block px-4 py-3 text-sm capitalize transition-colors duration-200 transform text-gray-300 hover:bg-gray-700 hover:text-white"> Profile </Link></Menu.Item>
          <Menu.Item><Link to="/my-collection" className="block px-4 py-3 text-sm capitalize transition-colors duration-200 transform text-gray-300 hover:bg-gray-700 hover:text-white">NFT Certifications</Link></Menu.Item>
          <Menu.Item><Link to="/settings" className="block px-4 py-3 text-sm capitalize transition-colors duration-200 transform text-gray-300 hover:bg-gray-700 hover:text-white"> Settings </Link></Menu.Item>
          <Menu.Item><Link to="https://discord.gg/UQayXxzazc" target="_blank" className="block px-4 py-3 text-sm capitalize transition-colors duration-200 transform text-gray-300 hover:bg-gray-700 hover:text-white">Join Our Discord</Link></Menu.Item>
          <Menu.Item><Link to="/feedback" className="block px-4 py-3 text-sm capitalize transition-colors duration-200 transform text-gray-300 hover:bg-gray-700 hover:text-white">Feedback</Link></Menu.Item>
          <Menu.Item><div className="cursor-pointer block px-4 py-3 text-sm capitalize transition-colors duration-200 transform text-gray-300 hover:bg-gray-700 hover:text-white" onClick={logout}> Log Out </div></Menu.Item>
        </Menu.Items>
      </Menu>
    </nav>
  )
}
