import { useEffect } from 'react';
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

export default function Feedback() {

  useEffect(() => {
    document.title = 'Feedback - Cadena';
    window.analytics.page(`Feedback - Cadena`);
  }, []);

  return (
    <div className="course-page">
      <Header />
      <main className="">
        <div className="flex">
          <div className="hidden h-screen sticky top-0 lg:block">
            <div className="sidebar min-h-screen w-64">
              <Sidebar />
            </div>
          </div>
          <div className="grid p-2 sm:p-10 w-full">
            <div className="w-full  max-w-none leading-6 rounded-b-md p-5">

              <section className="max-w-4xl p-10 md:p-20 mx-auto rounded-md shadow-md bg-gray-800">
                <h2 className="text-4xl font-bold text-white mb-10">Feedback</h2>
                <p className='text-gray-300 text-base mb-3'>Thank you for being a Cadena Candidate. We would love any feedback you may have. Want a new course or see room for improvement?  Feel free to fill out the form below to let us know. 💫</p>
                <div>
                  <iframe title="feedback" src="https://docs.google.com/forms/d/e/1FAIpQLSfBFyBu9hylOI70HzpGBLoaNxhdvXhbaMFx5GZiP5pdpvw5Jg/viewform?embedded=true" width="100%" height="912" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
