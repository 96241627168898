import { AuthContext, UserMetaData } from '../context/AuthContext'
import { useContext } from "react";

export const useAuthContext = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw Error('useAuthContext must be inside of an AuthContextProvider')
  }
  return context
}

export const useUserMetaDataContext = () => {
  const context = useContext(UserMetaData)
  if (!context) {
    throw Error('useUserMetaData must be inside of an AuthContextProvider')
  }
  return context
}