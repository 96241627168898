import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const StepTwo = (props) => {
  const stepTwoValidationSchema = Yup.object({
    codingExperience: Yup.string().required('Choose an option.')
  });
  const handleSubmit = (values) => {
    props.next(values, true);
  };
  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 mt-20">
      <div className="max-w-md w-full space-y-8 bg-gray-800 shadow-lg rounded-lg px-10 py-10">
        <div>
          <Formik
            validationSchema={stepTwoValidationSchema}
            initialValues={props.data}
            onSubmit={handleSubmit}
          >
            {({ values }) => (
              <Form>
                <h2 className="text-3xl font-bold text-white mb-5">
                  How experienced are you with coding?
                </h2>
                <p className="text-red-600"><ErrorMessage name="codingExperience" /></p>
                <label className="w-full flex justify-left items-center my-auto px-8 py-4 mx-auto rounded-lg shadow-md bg-gray-900 mt-0 mb-5">
                  <Field type="radio" name="codingExperience" value="no-experience" />
                  <p className="text-xl font-bold text-white hover:text-gray-200 text-left pl-5">No Coding Experience</p>
                </label>
                <label className="w-full flex justify-left items-center my-auto px-8 py-4 mx-auto rounded-lg shadow-md bg-gray-900 mt-0 mb-5">
                  <Field type="radio" name="codingExperience" value="less than 1" />
                  <p className="text-xl font-bold text-white hover:text-gray-200 text-left pl-5">Less Than 1 Year of Experience</p>
                </label>
                <label className="w-full flex justify-left items-center my-auto px-8 py-4 mx-auto rounded-lg shadow-md bg-gray-900 mt-0 mb-5">
                  <Field type="radio" name="codingExperience" value="1-2 years" />
                  <p className="text-xl font-bold text-white hover:text-gray-200 text-left pl-5">1-2 Years of Experience</p>
                </label>
                <label className="w-full flex justify-left items-center my-auto px-8 py-4 mx-auto rounded-lg shadow-md bg-gray-900 mt-0 mb-5">
                  <Field type="radio" name="codingExperience" value="over 2 years of experience" />
                  <p className="text-xl font-bold text-white hover:text-gray-200 text-left pl-5">2+ Years of Experience</p>
                </label>
                <div className="flex-1 flex justify-end sm:justify-between sm:items-center">
                  <div className="relative z-0 inline-flex rounded-md space-x-4 ">
                    <button
                      className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      type="button" onClick={() => props.prev(values)}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg> Back
                    </button>
                    <button
                      className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      type="submit">Next <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};



export default StepTwo;