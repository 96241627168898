import { useEffect, useState } from 'react';
import { useAuthContext } from "../hooks/useAuthContext";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import LogOut from "../components/LogOut";

export default function Profile() {
  const { user } = useAuthContext();

  const [userData, setUserData] = useState({ user })

  useEffect(() => {
    document.title = 'Settings - Cadena';
    window.analytics.page(`Settings - Cadena`);
  }, []);

  return (
    <div className="course-page">
      <Header />
      <main className="">
        <div className="flex">
          <div className="hidden h-screen sticky top-0 lg:block">
            <div className="sidebar min-h-screen w-64">
              <Sidebar />
            </div>
          </div>
          <div className="grid p-2 sm:p-10 w-full">
            <div className="w-full  max-w-none leading-6 rounded-b-md p-5">

              <section className="max-w-4xl p-10 sm:p-20 mx-auto rounded-md shadow-md bg-gray-800">
                <h2 className="text-4xl font-bold text-white mb-10">Settings</h2>

                <form>
                  <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                    <div>
                      <label className="text-gray-200" htmlFor="username">Email Address</label>
                      <input
                        id="username"
                        type="text"
                        className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"
                        readOnly
                        value={userData.user.email}
                      />
                    </div>

                    <div>
                      <label className="text-gray-200" htmlFor="emailAddress">Email Address Confirmation</label>
                      <input
                        id="emailAddress"
                        type="email"
                        className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"
                        readOnly
                        value={userData.user.email}
                      />
                    </div>

                    <div>
                      <label className="text-gray-200" htmlFor="password">Password</label>
                      <input id="password" type="password" className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring" readOnly value={12345678} />
                    </div>

                    <div>
                      <label className="text-gray-200" htmlFor="passwordConfirmation">Password Confirmation</label>
                      <input id="passwordConfirmation" type="password" className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-800 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring" readOnly value={12345678} />
                    </div>
                  </div>

                  <div className="flex space-x-4 justify-end mt-6">
                    <LogOut />
                    <button className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
