import { useState } from 'react'
import { useResetPassword } from "../hooks/useResetPassword"


export default function NewPassword({ oobCode }) {
  const [password, setPassword] = useState('')
  const { successMsg, error, forgotPassword } = useResetPassword();


  const handleSubmit = (e) => {
    e.preventDefault()
    forgotPassword(oobCode, password)
  }

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 mt-20">
      <div className="max-w-md w-full space-y-8 bg-gray-800 shadow-lg rounded-lg px-10 py-20">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="https://cadena.dev/wp-content/uploads/2021/12/cadena-logo@4x.png"
            alt="Cadena Logo"
          />
          <h2 className="mt-6 text-center text-3xl font-bold text-white">Enter Your New Password</h2>
        </div>
        <form
          onSubmit={handleSubmit}>

          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2">
              Password
            </label>
            <input
              required
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              className="border border-gray-600 shadow appearance-none rounded w-full py-2 px-3 bg-gray-800 text-gray-300 leading-tight focus:outline-none focus:shadow-outline"

            />
          </div>
          <button
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >Reset Password
          </button>
          {error && <p className="text-red-500 text-xs italic mt-3">{error}</p>}
          {successMsg && <p className="text-green-500 text-xs italic mt-3">{successMsg}</p>}
        </form>
      </div>
    </div>
  )
}
