import { useCollection } from "../../hooks/useCollection";
import { Link } from "react-router-dom";

const StepThree = (props) => {
  const { documents: courses } = useCollection("courses");

  const filteredCourses = (course) => {
    if (props.data.codingExperience === "no-experience") {
      return course.codingExperience === 0;
    }
    return course.codingExperience === 0;
  };

  return (
    <div className="mx-auto md:py-10 md:px-20">
      <p className="text-white text-2xl md:px-10 px-10 pt-5">Here are some courses we recommend you start with.</p>
      <div className="grid md:grid-cols-2 md:gap-10 p-10">
        <>
          {courses && courses.map(course => (
            <Link
              to={`/course/${(course.title).replace(/ /g, "-").toLowerCase()}/${course.id}`}
              key={course.id}
              className="col-1 overflow-hidden shadow-lg rounded-lg bg-gray-800 mb-8 md:mb-0 relative"
            >
              <img className="w-full h:32 sm:max-h-52 object-cover" src={course.imgCover} alt="course thumbnail" />
              <div className="blurb px-8 pt-5 pb-4">
                <h2 className="text-2xl font-bold text-white">
                  {course.title}
                </h2>
                <p className="mt-2 text-gray-300 text-base mb-6">
                  {course.blurb}
                </p>
              </div>
              {course.cert &&
                <div className=" bg-indigo-600 text-white text-xs uppercase font-bold rounded-lg p-2 absolute top-0 ml-6 mt-4">
                  <span>Earn NFT Certificate</span>
                </div>}
              <div className=" bg-green-600 text-white text-xs uppercase font-bold rounded-lg p-2 absolute top-0 right-0 mt-4 mr-6">
                <span>{course.completionTime}</span>
              </div>
            </Link>
          ))
          }
        </>
      </div>
    </div>
  );
};

export default StepThree;