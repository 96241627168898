import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuthContext } from "../hooks/useAuthContext";
import { db } from '../firebase/config'
import { getDocs, where, query, collection } from 'firebase/firestore';

export default function Lesson() {
  const { title, id: courseId } = useParams();
  const { user } = useAuthContext()
  const [filteredLessons, setFilteredLessons] = useState([]);
  const [completedLessons, setCompletedLessons] = useState({});
  const [lessonFileNames, setLessonFileNames] = useState(null);
  const [results, setResults] = useState([]);

  useEffect(() => {
    (async () => {
      const q = query(collection(db, "lessons"), where("courseId", "==", courseId));
      let results = []
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        //console.log("new log", doc.id, " => ", doc.data());
        results.push(doc.data())
      });
      setFilteredLessons(results);
      setLessonFileNames(results.map(lesson => lesson.markDownURI));
    })()

  }, [courseId])

  //set-total lessons
  localStorage.setItem(`${title}`, `${filteredLessons.length + 1}`);

  //set-completed lessons
  useEffect(() => {
    (async () => {

      if (lessonFileNames != null) {

        let queries = [];

        for (let i = 0; i < lessonFileNames.length; i += 10) {
          queries.push(query(
            collection(db, "submissions"),
            where("uid", "==", user.uid),
            where("lessonId", "in", lessonFileNames.slice(i, i + 10)),
          ));
        }

        //console.log('queries', queries);

        let usersDocsSnaps = [];

        for (let i = 0; i < queries.length; i++) {
          usersDocsSnaps.push(getDocs(queries[i]));
        }
        usersDocsSnaps = await Promise.all(usersDocsSnaps);
        //console.log('usersDocsSnaps', usersDocsSnaps);

        let usersDocs = [...new Set([].concat(...usersDocsSnaps.map((o) => o.docs)))];

        //console.log('usersDocs', usersDocs);
        let results = []
        usersDocs.forEach((doc) => {
          let completedLessonsTemp = {};
          //console.log("submissions", doc.id, " => ", doc.data());
          results.push(doc.data())
          //console.log("doc", doc.id);
          completedLessonsTemp[doc.data().lessonId] = true;
          setResults(results);
          setCompletedLessons(completedLessonsTemp)
          localStorage.setItem(`${title}-completed`, `${results.length + 1}`);
        })
      }
    })()

  }, [lessonFileNames, user.uid, title])

  // console.log('completedLessons', completedLessons);
  // console.log('results Length', results.length);
  // console.log('results', results);

  const markedCompleteLessons = {};
  results.forEach(item => markedCompleteLessons[item.lessonId] = true);

  const listLessons = filteredLessons.sort((a, b) => a.order - b.order).map(lesson => {
    return (
      <Link
        //change this to
        to={`/${courseId}/lesson/${title}/${lesson.markDownURI}/${lesson.order}`}
        key={lesson.order}
        className="w-full flex justify-between items-center my-auto px-8 py-4 mx-auto rounded-lg shadow-md bg-gray-800 mt-0 mb-5">
        <p className="text-2xl font-bold text-white hover:text-gray-200 text-left pr-5">{lesson.title}</p>

        <div className="check text-2xl">
          {
            markedCompleteLessons[lesson.markDownURI] ?
              <span>✅</span> :
              <span className="filter grayscale">✅</span>
          }
        </div>
      </Link>
    );
  })

  return (
    <>
      {listLessons}
    </>
  );

}