import { useState, useEffect } from 'react';
import { db } from '../firebase/config'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { useUserMetaDataContext } from "../hooks/useAuthContext";

import StepOne from "../components/onboarding/StepOne";
import StepTwo from "../components/onboarding/StepTwo";
import StepThree from "../components/onboarding/StepThree";

const OnBoarding = () => {
  const { user } = useUserMetaDataContext();
  const [data, setData] = useState({
    roles: [],
    other_role: "",
    codingExperience: "",
    timestamp: serverTimestamp()
  });

  useEffect(() => {
    document.title = 'Recommendations - Cadena';
    window.analytics.page(`Onboarding - Cadena`);
  }, []);


  //keep track of steps
  const [currentStep, setCurrentStep] = useState(0);


  // const makeRequest = (formData) => {
  //   console.log("Form Submitted", formData);
  // };

  //capture data from steps
  const handleNextStep = async (newData, final = false) => {
    setData((prev) => ({ ...prev, ...newData }));

    if (final) {
      //sabe data to firebase
      //makeRequest(newData);
      //return to stop inrementing
      //history.push("/dashboard");
      //return;
      try {
        await addDoc(collection(db, 'userExperience'), {
          uid: user.uid,
          dateSubmitted: serverTimestamp(),
          ...newData
        });
        window.analytics.track({
          userId: user.uid,
          event: `Onboarding Completed`
        });
      }
      catch (error) {
        console.log(error);
      }
    }

    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };

  const steps = [
    <StepOne next={handleNextStep} data={data} />,
    <StepTwo next={handleNextStep} prev={handlePrevStep} data={data} />,
    <StepThree next={handleNextStep} data={data} />
  ];

  console.log("data from steps", data);


  return (
    <>
      {steps[currentStep]}
    </>

  )
}

export default OnBoarding;