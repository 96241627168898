import { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { auth } from "../firebase/config";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";

export const useSignup = () => {
  const [error, setError] = useState(null)
  const { dispatch } = useAuthContext()

  const signup = (email, password) => {
    setError(null)
    createUserWithEmailAndPassword(auth, email, password)
      .then(res => {
        // console.log('response', res);
        console.log('user signed up', res.user);
        dispatch({ type: 'LOGIN', payload: res.user })
        sendEmailVerification(auth.currentUser)
        // history.push("/verify");
        //welcome email goes here.
      })
      .catch(err => {
        setError(err.message)
      })
  }

  return { error, signup }
}