import { addDoc, collection } from 'firebase/firestore';
import { serverTimestamp } from "firebase/firestore";
import { db } from '../firebase/config';
import { useUserMetaDataContext } from "../hooks/useAuthContext";

export const useWelcomeEmail = () => {
  const { user, authIsReady } = useUserMetaDataContext();

  const handleWelcomeEmail = async () => {
    try {
      addDoc(collection(db, 'mail'), {
        uid: user.uid,
        dateSubmitted: serverTimestamp(),
        to: `${user.email}`,
        from: `team@cadena.dev`,
        message: {
          subject: 'Welcome to Cadena 💌',
          html: `<p>We're excited to have you join the Cadena Community of like minded learners from all over the world 🌍.</p><p>Ready to get started on your journey to learn AI. Get started with learning GPT <a href="https://app.cadena.dev/course/gpt-101/brkDBkIxQUqe6afKrcMs">here</a> or if you're into Generative AI art you can check out our new course <a href="https://app.cadena.dev/course/generative-ai-art-101/Lya3ZDYdz7fajOiXuKRw">here</a>.</p><p>If you're new to Web3 or have no coding experience, our <a href="https://app.cadena.dev/course/ethereum-101/ZHjzLozd3mCsAcgMfeHE" target="_blank">Blockchain 101</a> course is a great place to start.</p><p>If you have experience with coding you can begin with our <a href="https://app.cadena.dev/course/ethereum-101/ZHjzLozd3mCsAcgMfeHE" target="_blank">Ethereum 101</a> course and earn your NFT Certificate. Be sure to also join our <a href="https://discord.com/invite/UQayXxzazc" target="_blank">Discord Community.</a> We also recently launched our NFT 101 course which can be found in the dashboard.</p><p>If you have any questions, let us know by replying to this email.</p><p>-The Cadena Team</p><p>Have a friend who you think might benefit from learning web3? Feel free to invite them by forwarding this email.</p>`
        }
      });

    } catch (err) {
      // Code is invalid or expired. Ask the user to verify their email address again
      console.log('email verification error', err);
    }
  }
  return { handleWelcomeEmail }

}