import { useState } from "react";
import { auth } from "../firebase/config";
import { confirmPasswordReset } from "firebase/auth";
import { useHistory } from 'react-router-dom'

export const useResetPassword = () => {
  const [error, setError] = useState(null)
  const [successMsg, setSuccessMsg] = useState(null)
  const history = useHistory()

  const forgotPassword = (oobCode, newPassword) => {
    setError(null)
    confirmPasswordReset(auth, oobCode, newPassword)
      .then(res => {
        console.log('response', res);
        // console.log('user signed up', res.user);
        setSuccessMsg("Password Changed!")
        history.push('/login')
        //dispatch({ type: 'LOGIN', payload: res.user })
      })
      .catch(err => {
        setError(err.message)
        console.log("Error Password Reset", err);
      })
  }

  return { successMsg, error, forgotPassword }
}