import { createContext, useReducer, useEffect, useState } from 'react'
import { auth } from '../firebase/config'
import { onAuthStateChanged } from "firebase/auth";
import { db } from '../firebase/config'
import { query, where, onSnapshot, collection } from 'firebase/firestore';

//create context, AuthConext is obj, that has provider, consumer
export const AuthContext = createContext()
export const UserMetaData = createContext()

//auth reducer function, takes in prevstate and action, the dispacter dispatches actions like login/logout
//dispatchers fire the authReducer function
export const authReducer = (state, action) => {
  //func then looks at action type
  switch (action.type) {
    case 'LOGIN':
      //action payload is uer loggedin
      //these returns are only changing the use obj
      return { ...state, user: action.payload }
    case 'LOGOUT':
      return { ...state, user: null }
    //only seth auth here to true
    case 'AUTH_IS_READY':
      return { user: action.payload, authIsReady: true }
    default:
      return state
  }
}

//react component to wrap context provider
//children is the component it will wrap ie app
export const AuthContextProvider = ({ children }) => {
  const [profileExists, setProfileExists] = useState(false);
  const [submissions, setSubmissions] = useState([]);
  const [profile, setProfile] = useState([]);
  const [userWalletAddress, setUserWalletAddress] = useState(null);
  //reducer is used for more complex state instead of useState
  //state and the dispatch func get passed as values

  //authReducer is a func, {} is initital state
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false
  })

  useEffect(() => {
    //fires function to figure out if user is logged in or not
    //onAuthStateChanged is a method used on Auth
    const unsub = onAuthStateChanged(auth, user => {
      dispatch({ type: 'AUTH_IS_READY', payload: user })
      unsub()
    })
  }, [])

  const { user, authIsReady } = state;

  //console.log('AuthContext State:', state);
  // console.log('AuthContext deconstruct:', user.uid)
  // console.log('AuthContext AuthIsReady:', authIsReady)
  useEffect(() => {
    if (!authIsReady || user == null) return null;
    const q = query(collection(db, "profiles"), where("uid", "==", `${user.uid}`));
    const unsubscribe = onSnapshot(q, querySnapshot => {
      const results = [];
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id })
        });
        setProfile([...results]);
        setProfileExists(true);
        console.log('profile query', results);
      } else {
        console.log('No matching profile documents.', profile);
      }
    });
    return () => unsubscribe();
  }, [authIsReady, user, profile.length])

  //find all user submissions and parse them to render % complete
  useEffect(() => {
    if (!authIsReady || user == null) return null;
    const q = query(collection(db, "submissions"), where("uid", "==", `${user.uid}`));
    const unsubscribe = onSnapshot(q, querySnapshot => {
      const results = [];
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id })
        });
        setSubmissions([...results]);
        console.log('submissions query', results);
      } else {
        console.log('No matching documents.', submissions);
      }
    });
    return () => unsubscribe();
  }, [authIsReady, user]);

  //finder user wallet
  useEffect(() => {
    if (!authIsReady || user == null) return null;
    const q = query(collection(db, "walletAddresses"), where("uid", "==", `${user.uid}`));
    const unsubscribe = onSnapshot(q, querySnapshot => {
      const results = [];
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id })
        });
        setUserWalletAddress([...results]);
        console.log('walletAddresses query', results);
      } else {
        console.log('No matching documents.', userWalletAddress);
      }
    });
    return () => unsubscribe();
  }, [authIsReady, user]);

  //console.log('submissions AUTH', submissions);

  return (
    //return template
    //value is global state deconstructed and  dispatch is the dispatch func
    //val is being provided to the children to consume
    //children is component we wrap
    <AuthContext.Provider value={{ ...state, dispatch }}>
      <UserMetaData.Provider value={{ user, profileExists, submissions, authIsReady, userWalletAddress }}>
        {children}
      </UserMetaData.Provider>
    </AuthContext.Provider>
  )

}