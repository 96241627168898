import { useEffect, useState } from 'react';
import { doc, getDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/config'

/*controls complete toggle for course lock*/
export default function ProgressBar({ submissions, courseId, user }) {
  const [percentCompleted, setPercentCompleted] = useState(0);
  useEffect(() => {
    switch (courseId) {
      case "jEDD59mBBeGKIpxXDnGl":
        setPercentCompleted(submissions.length !== 0 && submissions.filter(submission => submission.lessonId.includes('lesson-blockchain')).length / 9 * 100);
        break;
      case "ZHjzLozd3mCsAcgMfeHE":
        setPercentCompleted(submissions.length !== 0 && submissions.filter(submission => submission.lessonId.includes('lesson-eth')).length / 13 * 100);
        Math.round(percentCompleted) === 100 && courseCompleted(courseId, user.uid);
        break;
      case "MMT1TI4R7AjWqk2AFMg7":
        setPercentCompleted(submissions.length !== 0 && submissions.filter(submission => submission.lessonId.includes('web3-dictionary')).length / 1 * 100);
        break;
      case "Lya3ZDYdz7fajOiXuKRw":
        setPercentCompleted(submissions.length !== 0 && submissions.filter(submission => submission.lessonId.includes('lesson-ga')).length / 7 * 100);
        break;
      case "Vd93yh7073UK5iMnc5KU":
        setPercentCompleted(submissions.length !== 0 && submissions.filter(submission => submission.lessonId.includes('lesson-nft')).length / 5 * 100);
        break;
      case "brkDBkIxQUqe6afKrcMs":
        setPercentCompleted(submissions.length !== 0 && submissions.filter(submission => submission.lessonId.includes('lesson-gpt')).length / 5 * 100);
        break;
      default:
        setPercentCompleted(0)
        break;
    }
  }, [submissions, courseId, user.uid]);

  const courseCompleted = async (courseId, userId) => {
    const docRef = doc(db, 'countDownTimer', `${userId}${courseId}`);
    try {
      await updateDoc(docRef, {
        completed: true
      });
      console.log('course completed set');

    } catch (error) {
      console.log('course 100% error:', error);
    }

  }

  return (
    <div className="w-full bg-gray-200 rounded-full mb-5">
      <div
        className={
          percentCompleted !== 0 ?
            `bg-green-600 text-xs font-medium text-center p-0.5 leading-none rounded-full` :
            `text-xs text-blue-100 p-0.5 rounded-full`
        }
        style={{ width: `${percentCompleted}%` }}
      >
        {percentCompleted === 0 ? '0%' : `${Math.round(percentCompleted)}%`}</div>
    </div>
  );
}