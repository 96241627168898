import { useState, useEffect, useRef } from 'react';
import { db } from '../firebase/config'
import { collection, onSnapshot, query, where } from 'firebase/firestore';

export const useCollection = (c, _q) => {
  const [documents, setDocuments] = useState(null);
  const [error, setError] = useState(null);

  const q = useRef(_q).current

  useEffect(() => {
    let ref = collection(db, c)

    if (q) {
      ref = query(ref, where(...q))
    }

    const unsub = onSnapshot(ref, snapshot => {
      let results = []
      if (!snapshot.empty) {
        snapshot.docs.forEach(doc => {
          results.push({ ...doc.data(), id: doc.id })
        })
        setDocuments(results)
        //setError('no error')
      } else {
        setError("Course not found")
        console.log("DB Query Error: ", error)
      }
    })
    //unsub and watch q or else infinite loop and querys to fb
    return () => unsub()
  }, [c, q])

  //console.log('errors in db call', error);
  return { documents }
}
