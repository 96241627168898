import { useState, useEffect } from 'react'
import { applyActionCode, EmailAuthProvider } from 'firebase/auth';
import { auth } from "../firebase/config";
import { useHistory } from 'react-router-dom';
import { useWelcomeEmail } from "../hooks/useWelcomeEmail";
import { useUserMetaDataContext } from "../hooks/useAuthContext";

import { addDoc, collection } from 'firebase/firestore';
import { serverTimestamp } from "firebase/firestore";
import { db } from '../firebase/config'

export default function EmailVerification({ oobCode }) {
  const { handleWelcomeEmail } = useWelcomeEmail();
  const [verifyStatus, setVerifyStatus] = useState('Verifying...');
  const { user, authIsReady } = useUserMetaDataContext();
  console.log('oobCode', oobCode);

  const history = useHistory()

  useEffect(() => {
    const handleVerifyEmail = async () => {
      try {
        await applyActionCode(auth, oobCode);
        setVerifyStatus('Verified ✅');
        // Email address has been verified.
        //fire off welcome email here
        authIsReady && addDoc(collection(db, 'mail'), {
          uid: user.uid,
          dateSubmitted: serverTimestamp(),
          to: `${user.email}`,
          from: `team@cadena.dev`,
          message: {
            subject: 'Welcome to Cadena 💌',
            html: `<p>We're excited to have you join the Cadena Community of like minded learners from all over the world 🌍. If you're new to AI or have no coding experience, our <a href="https://app.cadena.dev/course/gpt-101/brkDBkIxQUqe6afKrcMs" target="_blank">GPT 101</a> course is a great place to start.</p><p>Be sure to also join our <a href="https://discord.com/invite/UQayXxzazc" target="_blank">Discord Community.</a></p><p>If you want to keep up with the latest news in AI, join our Weekly Prompt newsletter <a href="https://cadena.dev/newsletter/?cadena-welcome" target="_blank">here</a></p><p>If you have any questions, let us know by replying to this email.</p><p>-The Cadena Team</p><p>Have a friend who you think might benefit from learning web3? Feel free to invite them by forwarding this email.</p>`
          }
        });
        setTimeout(() => { history.push('/dashboard'); }, 3000);
      } catch (err) {
        // Code is invalid or expired. Ask the user to verify their email address again
        console.log('email verification error', err);
        setVerifyStatus('Verification failed ❌');
        history.push('/');
      }
    }
    if (auth.currentUser.providerData[0].providerId !== 'github.com') {
      handleVerifyEmail();
    }

  }, [oobCode]);

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 mt-20">
      <p className="text-white block">{verifyStatus}</p>
      <button
        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >Take Me Home 🏠
      </button>
    </div>
  )
}
