import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { PostHogProvider } from 'posthog-js/react'
import { AuthContextProvider } from "./context/AuthContext";
import {
  BrowserRouter
} from "react-router-dom";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

ReactDOM.render(
  <PostHogProvider
    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
    options={options}
  >
    <AuthContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthContextProvider>
  </PostHogProvider>,
  document.getElementById('root')
);