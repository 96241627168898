import { useEffect, useState } from 'react';
import { useUserMetaDataContext } from "../hooks/useAuthContext";
import { db } from '../firebase/config'
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Header from "../components/Header";
import PastExperience from "../components/profile/PastExperience";
import Sidebar from "../components/Sidebar";
import toast from 'react-hot-toast';
import { serverTimestamp } from "firebase/firestore";

export default function Profile() {
  const { user } = useUserMetaDataContext()
  const [saveState, setSaveState] = useState(false);
  const verticalCheckBoxOptions = [
    { key: 'Gaming', value: 'Gaming' },
    { key: 'DeFi', value: 'DeFi' },
    { key: 'DEXs', value: 'DEXs' },
    { key: 'Marketplaces', value: 'Marketplaces' },
    { key: 'Infastructure', value: 'Infastructure' }
  ]

  const notify = () => toast('Profile Saved ✅');

  const [userData, setUserData] = useState({
    uid: user.uid,
    profileUpdated: serverTimestamp(),
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    gitHubUrl: '',
    linkedinUrl: '',
    yearsOfExperience: '',
    currentRole: '',
    techStack: '',
    remotePreference: 'No',
    currentLocation: '',
    prefferedLocations: '',
    about: '',
    additionalInfo: '',
    web3roles: '',
    activelyLooking: "No",
    privateProfile: "No",
    shareWithCompanies: "No",
    verticalCheckBox: [],
    //if we manually switch this to true will it overwrite everytime they save
    filledOut: false,
    once: false,
    workExperience: []
  });


  const [pastExperience, setPastExperience] = useState([]);
  const [isSavingWE, setSavingWE] = useState(false);
  const DEFAULT_EXPERIENCE_INPUT = {
    title: null,
    blurb: null,
    startDate: null,
    endDate: null,
    company: null
  };
  const [experienceInput, setExperienceInput] = useState(DEFAULT_EXPERIENCE_INPUT);

  const handleExperienceInput = event => {
    console.log(`${event.target.name} => ${event.target.value}`);
    setExperienceInput({
      ...experienceInput,
      [event.target.name]: event.target.value
    });
  }
  // console.log("EXPERIENCE INPUT =>", experienceInput);



  //deconstruct userData
  const {
    uid,
    profileUpdated,
    firstName,
    lastName,
    email,
    username,
    gitHubUrl,
    linkedinUrl,
    yearsOfExperience,
    currentRole,
    techStack,
    remotePreference,
    currentLocation,
    prefferedLocations,
    about,
    web3roles,
    additionalInfo,
    activelyLooking,
    privateProfile,
    verticalCheckBox,
    shareWithCompanies,
    filledOut,
    workExperience
  } = userData;



  const submitWE = async () => {
    if (
      experienceInput.blurb &&
      experienceInput.startDate &&
      experienceInput.endDate &&
      experienceInput.title
    ) {

      pastExperience.push(experienceInput);

      pastExperience.sort((a, b) => {
        return new Date(b.startDate) - new Date(a.startDate);
      });

      try {
        await setDoc(doc(db, 'profiles', `${user.uid}`), {
          ...userData,
          workExperience: pastExperience
        }, { merge: true });
        setExperienceInput(DEFAULT_EXPERIENCE_INPUT);
      } catch (error) {
        console.log("WE ERROR", error);
      }
    }
  }

  const deleteExp = async (index) => {
    const tmpExp = pastExperience.filter((item, i) => {
      return i != index;
    });
    try {
      await setDoc(doc(db, 'profiles', `${user.uid}`), {
        ...userData,
        workExperience: tmpExp
      }, { merge: true });
      setPastExperience(tmpExp);
    } catch (error) {
      console.log(error);
    }
  }

  if (user && user.uid && !userData.once) {
    console.log(user);
    getDoc(doc(db, "profiles", user.uid)).then(doc => {
      if (doc.exists()) {
        setUserData({ ...userData, ...doc.data(), once: true });
        //setValues({ ...userData, ...doc.data() });
        if (doc.data().workExperience && JSON.stringify(pastExperience) !== JSON.stringify(doc.data().workExperience)) {
          // if the current pastExperiene is different from the incoming
          setPastExperience(doc.data().workExperience);
        }
      }
    });
  }

  console.log("pastExperience", pastExperience);

  useEffect(() => {
    document.title = 'Profile - Cadena';
    window.analytics.page(`Profile - Cadena`);
  }, []);

  return (
    <div className="course-page">
      <Header />
      <main className="">
        <div className="flex">
          <div className="hidden h-screen sticky top-0 lg:block">
            <div className="sidebar min-h-screen w-64">
              <Sidebar />
            </div>
          </div>
          <div className="grid p-2 sm:p-10 w-full">
            <div className="w-full  max-w-none leading-6 rounded-b-md p-5">
              <section className="max-w-4xl p-10 lg:p-20 mx-auto rounded-md shadow-md bg-gray-800">
                <h2 className="text-4xl font-bold text-white mb-4">Edit Profile</h2>
                <p className="text-gray-300 text-base mb-6">If you would like access to exclusive hiring oppurtunites 🚀 to Cadena Candidtes, fill out the profile below.By default your private is private and not shared with any companies.</p>
                <Formik
                  enableReinitialize
                  initialValues={{
                    uid: user.uid,
                    profileUpdated: serverTimestamp(),
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    username: username,
                    gitHubUrl: gitHubUrl,
                    linkedinUrl: linkedinUrl,
                    yearsOfExperience: yearsOfExperience,
                    currentRole: currentRole,
                    currentLocation: currentLocation,
                    techStack: techStack,
                    remotePreference: remotePreference,
                    about: about,
                    prefferedLocations: prefferedLocations,
                    web3roles: web3roles,
                    additionalInfo: additionalInfo,
                    activelyLooking: activelyLooking,
                    privateProfile: privateProfile,
                    verticalCheckBox: verticalCheckBox,
                    shareWithCompanies: shareWithCompanies,
                    filledOut: filledOut
                  }}
                  validationSchema={Yup.object({
                    firstName: Yup.string().required('Required'),
                    lastName: Yup.string().required('Required'),
                    email: Yup.string().email('Invalid email address').required('Required'),
                    username: Yup.string().required('Required'),
                    linkedinUrl: Yup.string().url('Invalid URL'),
                    gitHubUrl: Yup.string().url('Invalid URL').required('Required'),
                    yearsOfExperience: Yup.number().required('Required'),
                    currentRole: Yup.string().required('Required'),
                    techStack: Yup.string().required('Required'),
                    web3roles: Yup.string().required('Required'),
                    remotePreference: Yup.string().required('Required'),
                    activelyLooking: Yup.string().required('Required'),
                    privateProfile: Yup.string().required('Required'),
                    shareWithCompanies: Yup.string().required('Required'),
                    currentLocation: Yup.string().required('Required'),
                    prefferedLocations: Yup.string().required('Required'),
                    verticalCheckBox: Yup.array().required('Required'),
                  })}
                  onSubmit={async (values) => {
                    //setSubmitting(true);

                    try {
                      await setDoc(doc(db, 'profiles', `${user.uid}`), {
                        uid: user.uid,
                        profileUpdated: serverTimestamp(),
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        username: values.username,
                        gitHubUrl: values.gitHubUrl,
                        linkedinUrl: values.linkedinUrl,
                        yearsOfExperience: values.yearsOfExperience,
                        currentRole: values.currentRole,
                        techStack: values.techStack,
                        remotePreference: values.remotePreference,
                        activelyLooking: values.activelyLooking,
                        about: values.about,
                        web3roles: values.web3roles,
                        additionalInfo: values.additionalInfo,
                        shareWithCompanies: values.shareWithCompanies,
                        currentLocation: values.currentLocation,
                        prefferedLocations: values.prefferedLocations,
                        verticalCheckBox: values.verticalCheckBox
                      }, { merge: true });
                      setSaveState(true);
                      window.analytics.track({
                        userId: user.uid,
                        event: 'Profile Created'
                      });
                      notify();
                    } catch (error) {
                      console.log('save error', error);
                      console.log('userId', user.uid);
                    }
                    //setSubmitting(false);
                    console.log('value saved');

                  }}
                >
                  {({ values, isSubmitting, setValues, touched, errors, handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                        <div className="input-holder">
                          <label className="text-gray-200" htmlFor="firstName">First Name*</label>
                          <Field
                            name="firstName"
                            type="input"
                            className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-900 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"
                          />
                          {touched.firstName && errors.firstName ? <p className="text-red-500 text-xs italic">{errors.firstName}</p> : null}
                        </div>

                        <div className="input-holder">
                          <label className="text-gray-200" htmlFor="lastName">Last Name*</label>
                          <Field
                            name="lastName"
                            type="input"
                            className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-900 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"
                          />
                          {touched.lastName && errors.lastName ? <p className="text-red-500 text-xs italic">{errors.lastName}</p> : null}
                        </div>
                        <div className="input-holder">
                          <label className="text-gray-200" htmlFor="email">Email*</label>
                          <Field
                            name="email"
                            type="email"
                            className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-900 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"
                          />
                          {touched.email && errors.email ? <p className="text-red-500 text-xs italic">{errors.email}</p> : null}
                        </div>

                        <div className="input-holder">
                          <label className="text-gray-200" htmlFor="username">Cadena Username*</label>
                          <Field
                            name="username"
                            type="input"
                            className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-900 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"
                          />
                          {touched.username && errors.username ? <p className="text-red-500 text-xs italic">{errors.username}</p> : null}
                        </div>

                        <div className="input-holder">
                          <label className="text-gray-200" htmlFor="gitHubUrl">Github Profile*</label>
                          <Field
                            name="gitHubUrl"
                            placeholder="https://github.com/username"
                            type="input"
                            className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-900 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"
                          />
                          {touched.gitHubUrl && errors.gitHubUrl ? <p className="text-red-500 text-xs italic">{errors.gitHubUrl}</p> : null}
                        </div>
                        <div className="input-holder">
                          <label className="text-gray-200" htmlFor="linkedinUrl">Linkedin Profile</label>
                          <Field
                            name="linkedinUrl"
                            placeholder="https://www.linkedin.com/in/username"
                            type="input"
                            className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-900 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"
                          />
                          {touched.linkedinUrl && errors.linkedinUrl ? <p className="text-red-500 text-xs italic">{errors.linkedinUrl}</p> : null}
                        </div>

                        {/* {end of form container} */}
                      </div>
                      <h2 className="text-3xl font-bold text-white mt-10 mb-4">Work Experience</h2>
                      {/* {start of work experience} */}
                      <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                        <div>
                          <label className="text-gray-200" htmlFor="yearsOfExperience">Years of Experience*</label>
                          <Field
                            name="yearsOfExperience"
                            placeholder="Ex. 3"
                            type="input"
                            className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-900 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"
                          />
                          {touched.yearsOfExperience && errors.yearsOfExperience ? <p className="text-red-500 text-xs italic">{errors.yearsOfExperience}</p> : null}
                        </div>
                        <div>
                          <label className="text-gray-200" htmlFor="currentRole">Current Role*</label>
                          <Field
                            name="currentRole"
                            placeholder="Ex. Student, Software Engineer, Infra Engineer"
                            type="input"
                            className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-900 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"
                          />
                          {touched.currentRole && errors.currentRole ? <p className="text-red-500 text-xs italic">{errors.currentRole}</p> : null}
                        </div>
                      </div>
                      {/* {end of work experience} */}
                      <div className="mt-4">
                        <label className="text-gray-200" htmlFor="techStack">What languages, frameworks, and tools are you experienced in?*</label>
                        <Field
                          name="techStack"
                          placeholder="Ex. React, Solidity, NodeJS, Python, Rails, JavaScript, Go, etc."
                          component="textarea"
                          rows="3"
                          className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-900 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"
                        />
                        {touched.techStack && errors.techStack ? <p className="text-red-500 text-xs italic">{errors.techStack}</p> : null}
                      </div>
                      <div className="mt-4">
                        <label className="text-gray-200" htmlFor="techStack">What Web3 roles are you interested in?*</label>
                        <Field
                          name="web3roles"
                          placeholder="Ex. Smart Contract Developer, Infra Developer, Blockchain Developer, Gaming, DeFi, Protocols, etc."
                          component="textarea"
                          rows="3"
                          className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-900 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"
                        />
                        {touched.web3roles && errors.web3roles ? <p className="text-red-500 text-xs italic">{errors.web3roles}</p> : null}
                      </div>

                      <div className="mt-4">
                        <label className="text-gray-200">What verticals are you interested in?*</label>
                        <div className="flex justify-left">
                          {verticalCheckBoxOptions.map((option, index) => (
                            <div key={index} className="flex items-center justify-center pr-4 py-2 mr-1">
                              <Field
                                name="verticalCheckBox"
                                type="checkbox"
                                value={option.value}
                                className="text-indigo-500 focus:ring-indigo-400 focus:ring-opacity-25 border-gray-300 rounded-sm appearance-none h-4 w-4  transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                checked={values.verticalCheckBox.includes(option.value)}
                              />
                              <label className="text-gray-200 form-check-label inline-block" htmlFor={option.value}>{option.key}</label>
                            </div>

                          ))}
                        </div>
                        {touched.verticalCheckBox && errors.verticalCheckBox ? <p className="text-red-500 text-xs italic">{errors.verticalCheckBox}</p> : null}
                      </div>


                      <div className="mt-4">
                        <label className="text-gray-200" htmlFor="about">One or two lines about you for your Cadena Candidate profile.</label>
                        <Field
                          name="about"
                          placeholder="Ex. I'm an experience React Developer, transitioning to Web3."
                          component="textarea"
                          rows="3"
                          className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-900 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"
                        />
                        {touched.about && errors.about ? <p className="text-red-500 text-xs italic">{errors.about}</p> : null}
                      </div>
                      <div className="mt-4">
                        <label className="text-gray-200" htmlFor="additionalInfo">Any additionl info you would like to share.</label>
                        <Field
                          name="additionalInfo"
                          placeholder="Ex. Portfolio links, link to your website, etc."
                          component="textarea"
                          rows="3"
                          className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-900 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"
                        />
                        {touched.additionalInfo && errors.additionalInfo ? <p className="text-red-500 text-xs italic">{errors.additionalInfo}</p> : null}
                      </div>

                      {/* {start of past experience} */}
                      <h2 className="text-2xl font-bold text-white mt-10 mb-4">Work Experience</h2>
                      <p className="text-gray-300">Add two to three of your most recent work experience and how long you worked there with a detailed description of your role.</p>
                      <PastExperience deleteExp={deleteExp} pastExperience={pastExperience} />
                      <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2 text-gray-200">
                        Job Title: <input
                          onChange={handleExperienceInput}
                          placeholder="Job Title e.g. Senior Software Engineer"
                          name="title"
                          value={experienceInput.title || ""}
                          className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-900 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"

                        />
                        Company: <input
                          onChange={handleExperienceInput}
                          placeholder="Company You Worked For. e.g. Google"
                          name="company"
                          value={experienceInput.company || ""}
                          className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-900 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"

                        />
                        <span className="italic text-red-400">Type the name of the month and year for the date fields.</span>
                        <span className="italic text-red-400">Ex. January 2022</span>
                        Start Date: <input
                          onChange={handleExperienceInput}
                          name="startDate"
                          type="month"
                          value={experienceInput.startDate || "2020-07"}
                          className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-900 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"
                        />

                        End Date: <input
                          onChange={handleExperienceInput}
                          name="endDate"
                          type="month"
                          value={experienceInput.endDate || "2022-05"}
                          className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-900 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"

                        />
                        Detailed Work Experience. Use "-" and line breaks to add multiple bullet points: <textarea
                          onChange={handleExperienceInput}
                          rows="6"
                          placeholder="
                          Example.&#13;&#10;
                            - Maintain and build out new workflows for CI/CD using Jenkins, and Github Actions.&#13;&#10;
                            - Collaborate with feature developers to get their applications ready for the migration to the cloud. This
                            includes leading efforts to containerize applications that were built specifically for bare metal
                            environments.&#13;&#10;
                            - Implement a process to use Sonarqube for continuous code quality analysis across all the company’s
                            tech stacks (python, java, ruby, and javascript).
                          "
                          name="blurb"
                          value={experienceInput.blurb || ""}
                          className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-900 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"

                        ></textarea>
                        <div></div>
                        <button
                          type="button"
                          id="work-experience"
                          onClick={submitWE}
                          className="px-6 py-2 leading-5 text-white text-center transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                        >
                          <p>Add Experience</p>
                        </button>
                      </div>
                      {/* {end of past experience} */}

                      <h2 className="text-3xl font-bold text-white mt-10 mb-4">Preferences</h2>
                      {/* {start of work experience} */}
                      <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">

                        <div role="group" aria-labelledby="remote-roles" className="">
                          <span className="text-gray-200">Are you interested in remote only roles?*</span>
                          <div className="block">
                            <label className="inline-flex items-center mt-3 mr-4">
                              <Field
                                type="radio"
                                name="remotePreference"
                                className="form-radio h-5 w-5 text-green-600"
                                value="Yes"
                              />
                              <span className="ml-2 text-gray-200">Yes</span>
                            </label>
                            <label className="inline-flex items-center mt-3">
                              <Field
                                type="radio"
                                name="remotePreference"
                                className="form-radio h-5 w-5 text-red-600"
                                value="No"
                              />
                              <span className="ml-2 text-gray-200">No</span>
                            </label>
                            {touched.remotePreference && errors.remotePreference ? <p className="text-red-500 text-xs italic">{errors.remotePreference}</p> : null}
                          </div>
                        </div>


                        <div role="group" aria-labelledby="job-search" className="">
                          <span className="text-gray-200">Are you actively job searching?*</span>
                          <div className="block">
                            <label className="inline-flex items-center mt-3 mr-4">
                              <Field
                                type="radio"
                                name="activelyLooking"
                                className="form-radio h-5 w-5 text-green-600"
                                value="Yes"
                              />
                              <span className="ml-2 text-gray-200">Yes</span>
                            </label>
                            <label className="inline-flex items-center mt-3">
                              <Field
                                type="radio"
                                name="activelyLooking"
                                className="form-radio h-5 w-5 text-red-600"
                                value="No"
                              />
                              <span className="ml-2 text-gray-200">No</span>
                            </label>
                            {touched.activelyLooking && errors.activelyLooking ? <p className="text-red-500 text-xs italic">{errors.activelyLooking}</p> : null}
                          </div>
                        </div>

                      </div>
                      {/* {end of work experience} */}
                      {/* {start of work experience} */}
                      <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                        <div>
                          <label className="text-gray-200" htmlFor="currentLocation">Current Location*</label>
                          <Field
                            name="currentLocation"
                            placeholder="Ex. New York, NY"
                            type="input"
                            className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-900 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"
                          />
                          {touched.currentLocation && errors.currentLocation ? <p className="text-red-500 text-xs italic">{errors.currentLocation}</p> : null}
                        </div>
                        <div>
                          <label className="text-gray-200" htmlFor="currentRole">Preffered Locations*</label>
                          <Field
                            name="prefferedLocations"
                            placeholder="Ex. NYC, Bay Area, SF, LA, Miami, Austin, etc."
                            type="input"
                            className="block w-full px-4 py-2 mt-2 border rounded-md  bg-gray-900 text-gray-300 border-gray-600 focus:border-blue-500 focus:outline-none focus:ring"
                          />
                          {touched.prefferedLocations && errors.prefferedLocations ? <p className="text-red-500 text-xs italic">{errors.prefferedLocations}</p> : null}
                        </div>
                      </div>
                      {/* {end of work experience} */}

                      <h2 className="text-3xl font-bold text-white mt-10 mb-4">Privacy</h2>
                      {/* {start of work experience} */}
                      <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">

                        <div role="group" aria-labelledby="remote-roles" className="">
                          <span className="text-gray-200">Share Profile With Companies?*</span>
                          <div className="block">
                            <label className="inline-flex items-center mt-3 mr-4">
                              <Field
                                type="radio"
                                name="shareWithCompanies"
                                className="form-radio h-5 w-5 text-green-600"
                                value="Yes"
                              />
                              <span className="ml-2 text-gray-200">Yes</span>
                            </label>
                            <label className="inline-flex items-center mt-3">
                              <Field
                                type="radio"
                                name="shareWithCompanies"
                                className="form-radio h-5 w-5 text-red-600"
                                value="No"
                              />
                              <span className="ml-2 text-gray-200">No</span>
                            </label>
                            {touched.shareWithCompanies && errors.shareWithCompanies ? <p className="text-red-500 text-xs italic">{errors.shareWithCompanies}</p> : null}
                          </div>
                        </div>


                        <div role="group" aria-labelledby="job-search" className="">
                          <span className="text-gray-200">Make your profile public?*</span>
                          <div className="block">
                            <label className="inline-flex items-center mt-3 mr-4">
                              <Field
                                type="radio"
                                name="privateProfile"
                                className="form-radio h-5 w-5 text-green-600"
                                value="Yes"
                              />
                              <span className="ml-2 text-gray-200">Yes</span>
                            </label>
                            <label className="inline-flex items-center mt-3">
                              <Field
                                type="radio"
                                name="privateProfile"
                                className="form-radio h-5 w-5 text-red-600"
                                value="No"
                              />
                              <span className="ml-2 text-gray-200">No</span>
                            </label>
                            {touched.privateProfile && errors.privateProfile ? <p className="text-red-500 text-xs italic">{errors.privateProfile}</p> : null}
                          </div>
                        </div>

                      </div>
                      {/* {end of work experience} */}

                      <div className="flex justify-end mt-6">
                        <button disabled={isSubmitting} type="submit" className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Save</button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </section>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
