import { useState, useEffect } from 'react';
import { getTimeRemainingUtilMsTimestamp } from "../utils/CountDownTimerUtils";
import { useCollection } from "../hooks/useCollection";
import { useUserMetaDataContext } from "../hooks/useAuthContext";
import { doc, setDoc, getDoc, onSnapshot, query, collection, where } from 'firebase/firestore';
import { db } from '../firebase/config'

const CountDown = ({ couseId }) => {
  const { user } = useUserMetaDataContext()
  const [countdownTimestampMs, setCountdownTimestampMs] = useState(0);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "countDownTimer", `${user.uid}ZHjzLozd3mCsAcgMfeHE`), (doc) => {
      //console.log("Current data: ", doc.data());
      if (doc.exists()) {
        setCountdownTimestampMs(doc.data().endTime);
      }
    });
    return () => unsub()
  }, [])



  const [remainingTime, setRemainingTime] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(countdownTimestampMs);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [countdownTimestampMs])

  function updateRemainingTime(countdown) {
    setRemainingTime(getTimeRemainingUtilMsTimestamp(countdown));
  }

  return (
    <>
      <div>
        <div className="grid grid-flow-col gap-5 justify-center	 text-center auto-cols-max text-white bg-indigo-600 rounded">
          <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
            <span className="font-mono text-5xl countdown">
              <span>{remainingTime.days}</span>
            </span>
            days
          </div>
          <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
            <span className="font-mono text-5xl countdown">
              <span>{remainingTime.hours}</span>
            </span>
            hours
          </div>
          <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
            <span className="font-mono text-5xl countdown">
              <span>{remainingTime.minutes}</span>
            </span>
            min
          </div>
          <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
            <span className="font-mono text-5xl countdown">
              <span>{remainingTime.seconds}</span>
            </span>
            sec
          </div>
        </div>
        <p className="text-white mt-3 mb-3">By starting the course you will have 7 days to complete the course to earn your NFT Certificate.</p>
      </div>
    </>
  );
}

export default CountDown;