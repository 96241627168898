import { Link } from 'react-router-dom'
import Navbar from './Navbar'
export default function Header() {

  return (
    <div className="lg:hidden">
      <header className="flex items-center justify-between flex-wrap p-6 bg-gray-800 border-b border-gray-600">
        <div className="logo">
          <Link to="/">
            <img className="w-2/3" src="https://cadena.dev/wp-content/uploads/2021/11/cadena-logo.png" alt="Cadena Logo" />
          </Link>
        </div>
        <div className="navbar">
          <Navbar />
        </div>
      </header>
    </div>
  )
}
