import React, { useEffect } from 'react';
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { Helmet } from "react-helmet"
import { useParams } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';

const SunyBronx = () => {


  const { title } = useParams();
  const posthog = usePostHog();


  useEffect(() => {
    document.title = `SUNY Bronx EOC`;
    window.analytics.page(`SUNY Bronx EOC`);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [title]);

  return (
    <div className="course-page">
      <Header />
      <main className="">
        <div className="flex">
          <div className="hidden h-screen sticky top-0 lg:block">
            <div className="sidebar min-h-screen w-64">
              <Sidebar />
            </div>
          </div>
          <div className="p-10 w-full">

            <iframe
              title="SUNY Bronx EOC"
              width="100%"
              style={{ height: '600px' }}
              src="https://kastro.chat/chat/103:aaa8590b3cc1d50c601c52ede9dd03b9?full_screen=true"
              frameBorder="0"
            ></iframe>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SunyBronx;