import { useState, useEffect } from 'react'
import { useLogin } from "../hooks/useLogin";
import { useLocation } from 'react-router-dom';
import AuthButtons from "../components/AuthButtons";
import posthog from 'posthog-js'

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  let location = useLocation();

  const { error, login } = useLogin()

  const handleSubmit = (e) => {
    e.preventDefault()
    login(email, password)
  }

  useEffect(() => {
    document.title = 'Login - Cadena';
    window.analytics.page(`Login - Cadena`);
    posthog.capture('$pageview');

  }, [location]);

  return (
    <div className="min-h-full flex items-center justify-center mt-20 py-0 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-gray-800 shadow-lg rounded-lg px-10 py-10">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="https://cadena.dev/wp-content/uploads/2021/12/cadena-logo@4x.png"
            alt="Cadena Logo"
          />
          <h2 className="mt-6 text-center text-3xl font-bold text-white">Log in to your account</h2>
        </div>
        <form
          onSubmit={handleSubmit}>

          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2">
              Email
            </label>
            <input
              required
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="border border-gray-600 shadow appearance-none rounded w-full py-2 px-3 bg-gray-800 text-gray-300 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          <div className="mb-2">
            <label className="block text-white text-sm font-bold mb-2">
              Password
            </label>
            <input
              required
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              className="border border-gray-600 shadow appearance-none rounded w-full py-2 px-3 bg-gray-800 text-gray-300 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-6">
            <a className="text-indigo-600" href="/forgot-password">Forgot Password?</a>
          </div>
          <button
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >Log in
          </button>
          {error && <p className="text-red-500 text-xs italic mt-3">{error}</p>}
        </form>
        <AuthButtons category={"Login In"} disabled={false} />

      </div>
    </div>
  )
}
