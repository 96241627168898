import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { getAuth, getRedirectResult, GithubAuthProvider, GoogleAuthProvider, sendEmailVerification } from "firebase/auth";
import { useWelcomeEmail } from "../../hooks/useWelcomeEmail";
import { db } from '../../firebase/config'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';


const StepOne = (props) => {
  const { handleWelcomeEmail } = useWelcomeEmail();
  const [referral, setReferral] = useState(null);

  const auth = getAuth();

  useEffect(() => {
    const storedReferral = localStorage.getItem('referralSource');
    if (storedReferral) {
      setReferral(storedReferral);
    }
  }, []);

  useEffect(() => {
    if (referral && auth.currentUser) {
      addDoc(collection(db, 'referrals'), {
        uid: auth.currentUser.uid,
        referrer: referral,
        email: auth.currentUser.email,
        dateSubmitted: serverTimestamp(),
      });
    }
  }, [referral, auth.currentUser]);



  getRedirectResult(auth)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      console.log('credential', credential);
      if (credential.providerId === 'google.com') {
        handleWelcomeEmail();
      } else {
        handleWelcomeEmail();
        console.log('welcome email');
        sendEmailVerification(auth.currentUser);
        console.log('verified email');
      }
      const user = result.user;

    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GithubAuthProvider.credentialFromError(error);
      // ...
    });

  const stepOneValidationSchema = Yup.object({});

  const handleSubmit = (values) => {
    props.next(values);
  };

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 mt-20">
      <div className="max-w-md w-full space-y-8 bg-gray-800 shadow-lg rounded-lg px-10 py-10">
        <div>
          <Formik
            validationSchema={stepOneValidationSchema}
            initialValues={props.data}
            onSubmit={handleSubmit}
          >
            {({ values, dirty, isValid }) => (
              <Form>
                <h2 className="text-3xl font-bold text-white mb-1">
                  What best describes you?
                </h2>
                <p className="text-indigo-600 mb-5">(check all that apply)</p>
                <label className="w-full flex justify-left items-center my-auto px-8 py-4 mx-auto rounded-lg shadow-md bg-gray-900 mt-0 mb-5">
                  <Field type="checkbox" name="roles" value="designer" />
                  <p className="text-xl font-bold text-white hover:text-gray-200 text-left pl-5">Designer</p>
                </label>
                <label className="w-full flex justify-left items-center my-auto px-8 py-4 mx-auto rounded-lg shadow-md bg-gray-900 mt-0 mb-5">
                  <Field type="checkbox" name="roles" value="developer" />
                  <p className="text-xl font-bold text-white hover:text-gray-200 text-left pl-5">Developer</p>
                </label>
                <label className="w-full flex justify-left items-center my-auto px-8 py-4 mx-auto rounded-lg shadow-md bg-gray-900 mt-0 mb-5">
                  <Field type="checkbox" name="roles" value="product-manager" />
                  <p className="text-xl font-bold text-white hover:text-gray-200 text-left pl-5">Product Manager</p>
                </label>
                <label className="w-full flex justify-left items-center my-auto px-8 py-4 mx-auto rounded-lg shadow-md bg-gray-900 mt-0 mb-5">
                  <Field type="checkbox" name="roles" value="project-manager" />
                  <p className="text-xl font-bold text-white hover:text-gray-200 text-left pl-5">Project Manager</p>
                </label>
                <label className="w-full flex justify-left items-center my-auto px-8 py-4 mx-auto rounded-lg shadow-md bg-gray-900 mt-0 mb-5">
                  <Field className="w-full text-white bg-gray-900 placeholder-shown:border-gray-900  focus:outline-none focus:ring-1 focus:ring-gray-900" type="input" name="other_role" placeholder="Other..." />
                </label>
                <button
                  className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${!(dirty && isValid) ? "bg-indigo-400" : "bg-indigo-600"} hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  disabled={!(dirty && isValid)}
                  type="submit">
                  Next<svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                  </svg>
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default StepOne;