import { useState } from "react";
import { auth } from "../firebase/config";
import { sendPasswordResetEmail } from "firebase/auth";

export const useForgotPassword = () => {
  const [error, setError] = useState(null)

  const forgotPassword = (email) => {
    setError(null)
    sendPasswordResetEmail(auth, email, {
      url: "https://app.cadena.dev/login"
    })
      .then(res => {
        console.log('response', res);
        // console.log('user signed up', res.user);
        setError("Check Your Email to Reset Your Password.")
        //dispatch({ type: 'LOGIN', payload: res.user })
      })
      .catch(err => {
        setError(err.message)
        console.log("Error Password Reset", err);
      })
  }

  return { error, forgotPassword }
}