export default function PastExperience({ pastExperience = [], deleteExp }) {

  console.log("[PastExperience.js]", pastExperience);

  return (
    <>
      {pastExperience.map(
        (exp, index) =>
          <div key={`pastexp${index}`} className="text-white">
            <p><b>{exp.company} - {exp.title}</b></p>
            <p><i>{exp.startDate} - {exp.endDate}</i></p>
            <p>{exp.blurb}</p>
            <button onClick={()=>deleteExp(index)} type="button" className="transform bg-gray-700 rounded-md hover:bg-gray-600 px-4 py-1">Delete</button>
            <br/>
            <br/>
          </div>
      )}
      <br/>
    </>
  );

}