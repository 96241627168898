import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useAuthContext } from "./hooks/useAuthContext";
import Dashboard from './pages/Dashboard'
import Login from './pages/Login';
import Signup from './pages/Signup';
import Lesson from './pages/LessonPage';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import Feedback from './pages/Feedback';
import MyCollection from './pages/MyCollection';
import LessonIndex from './pages/LessonIndex';
import ForgotPassword from './pages/ForgotPassword';
import ActionURL from './pages/ActionURL';
import Onboarding from './pages/Onboarding';
import NotFound from './pages/NotFound';
import AskCadena from './pages/AskCadena';
import SunyBronx from './pages/SunyBronx.js';
import { Toaster } from 'react-hot-toast';
import posthog from 'posthog-js'

//:coloruser
function App() {
  const { user, authIsReady } = useAuthContext();
  // let location = useLocation();
  // useEffect(() => {
  //   posthog.capture('$pageview');
  // }, [location]);

  return (
    <div className="app">
      {authIsReady && (
        <Router>
          <Switch>
            <Route exact path="/">
              {user && <Redirect to="/dashboard" />}
              {!user && <Redirect to="/signup" />}
            </Route>
            <Route path="/dashboard">
              {user && <Dashboard />}
              {!user && <Redirect to="/login" />}
            </Route>
            <Route path="/bronx-eoc">
              {user && <SunyBronx />}
              {!user && <Redirect to="/login" />}
            </Route>
            <Route path="/ask-cadena">
              {user && <AskCadena />}
              {!user && <Redirect to="/login" />}
            </Route>
            <Route path="/signup">
              {!user && <Signup />}
              {user && <Redirect to="/recommendations" />}
            </Route>
            <Route path="/login">
              {!user && <Login />}
              {user && <Redirect to="/dashboard" />}
            </Route>
            <Route path="/forgot-password">
              {!user && <ForgotPassword />}
              {user && <Redirect to="/dashboard" />}
            </Route>
            <Route path="/recommendations">
              {user && <Onboarding />}
              {!user && <Redirect to="/login" />}
            </Route>
            <Route path="/profile">
              {user && <Profile />}
              {!user && <Redirect to="/login" />}
            </Route>
            <Route path="/settings">
              {user && <Settings />}
              {!user && <Redirect to="/login" />}
            </Route>
            <Route path="/feedback">
              {user && <Feedback />}
              {!user && <Redirect to="/login" />}
            </Route>
            <Route path="/my-collection">
              {user && <MyCollection />}
              {!user && <Redirect to="/login" />}
            </Route>
            <Route exact path="/course">
              {user && <Redirect to="/dashboard" />}
              {!user && <Redirect to="/login" />}
            </Route>
            <Route path="/course/:title/:id">
              {user && <LessonIndex />}
              {!user && <Redirect to="/login" />}
            </Route>

            <Route path="/:courseId/lesson/:title/:markDownURI/:pageNum/">
              {user && <Lesson />}
              {!user && <Redirect to="/login" />}
            </Route>
            <Route path="/s">
              {!user && <ActionURL />}
              {user && <ActionURL />}
            </Route>
            <Route path='*'>
              <NotFound />
            </Route>
          </Switch>
        </Router>
      )}
      <Toaster />
    </div>
  );
}

export default App;
