import { useEffect, useState } from "react";
import { useCollection } from "../hooks/useCollection";
import { useUserMetaDataContext } from "../hooks/useAuthContext";
import { Link } from "react-router-dom";
import ProgressBar from "../components/ProgressBar";
import CountDown from "../components/CountDown";
import { doc, setDoc, getDoc, onSnapshot, query, collection, where } from 'firebase/firestore';
import { db } from '../firebase/config'
import dayjs from "dayjs";

export default function CourseList({ submissions }) {
  const { user } = useUserMetaDataContext()
  const { documents: courses } = useCollection("courses");
  const [docExits, setDocExits] = useState(false);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "countDownTimer", `${user.uid}ZHjzLozd3mCsAcgMfeHE`), (doc) => {
      //console.log("Current data: ", doc.data());
      if (doc.exists()) {
        setDocExits(true);
      }
    });
    return () => unsub()
  }, [user.uid])

  const handleClick = async (user, courseId) => {
    try {
      if (!docExits) {
        await setDoc(doc(db, 'countDownTimer', `${user.uid}${courseId}`), {
          courseId: courseId,
          uid: user.uid,
          startTime: dayjs().valueOf(),
          endTime: dayjs().add(7, 'day').valueOf(),
          started: true,
          tries: 3,
          completed: false,
          locked: false
        })
      } else {
        console.log('do noting');
      }

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {courses && courses.sort((a, b) => a.order - b.order).map(course => (
        <Link
          to={`/course/${(course.title).replace(/ /g, "-").toLowerCase()}/${course.id}`}
          key={course.id}
          className="col-1 overflow-hidden shadow-lg rounded-lg bg-gray-800 mb-8 md:mb-0 relative"
          onClick={() => handleClick(user, course.id)}
        >
          <img className="w-full h:32 sm:max-h-52 object-cover" src={course.imgCover} alt="course thumbnail" />
          <div className="blurb px-8 pt-5 pb-4">
            <h2 className="text-2xl font-bold text-white">
              {course.title}
            </h2>
            <p className="mt-2 text-gray-300 text-base mb-6">
              {course.blurb}
            </p>
            <ProgressBar submissions={submissions} courseId={course.id} user={user} />
            {course.timeLock && <CountDown />}
          </div>
          {course.cert &&
            <div className=" bg-indigo-600 text-white text-xs uppercase font-bold rounded-lg p-2 absolute top-0 ml-6 mt-4">
              <span>Earn NFT Certificate</span>
            </div>}
          <div className=" bg-green-600 text-white text-xs uppercase font-bold rounded-lg p-2 absolute top-0 right-0 mt-4 mr-6">
            <span>{course.completionTime}</span>
          </div>
        </Link>
      ))
      }
    </>
  )
}