import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthContext } from "../hooks/useAuthContext";
import { Link } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react'


import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import MarkCompleteButton from "../components/MarkCompleteButton";
import ProjectSubmission from "../components/ProjectSubmission";
import ChatBot from "../components/ChatBot";

import ProgressBar from "../components/ProgressBar";
import { useUserMetaDataContext } from "../hooks/useAuthContext";

import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BiCopy } from 'react-icons/bi';

export default function Lesson() {
  const { user } = useAuthContext();
  const { title, markDownURI, pageNum, courseId } = useParams();
  const { submissions } = useUserMetaDataContext();
  const posthog = usePostHog()
  const [isShown, setIsShown] = useState(false);

  const toggle = () => {
    setIsShown(!isShown);
    posthog.capture('ChatBot Clicked');

  };


  const [post, setPost] = useState('');
  const [hideMarkComplete, setHideMarkComplete] = useState(false);
  const lessonLength = localStorage.getItem(`${title}`);
  const [lessonLengthState, setLessonLengthState] = useState(lessonLength);
  const [lessonSubmission, setLessonSubmission] = useState(false);
  const [buttonState, setButtonState] = useState({
    nextButton: false,
    prevButton: false,
  });


  useEffect(() => {
    setButtonState(prevState => (
      {
        ...prevState,
        nextButton: parseInt(pageNum) + 1,
        prevButton: parseInt(pageNum) - 1,
      }
    ))

  }, [pageNum])

  const { nextButton, prevButton } = buttonState;
  const prevButtonHide = prevButton === 0 && 'hidden';
  const nextButtonHide = nextButton >= lessonLengthState && 'hidden';
  const markDownString = markDownURI.replace(/\d+/g, '');
  console.log('markDownString', markDownString)

  useEffect(() => {
    let newTitle = title.replace(/-/g, ' ');
    const finalTitle = newTitle.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    document.title = `${finalTitle} | Course Section`;
    window.analytics.page(`${finalTitle} | ${markDownURI}`);
    window.scrollTo(0, 0)
  }, [title, markDownURI]);

  useEffect(() => {
    import(`../lessons/${markDownURI}.md`)
      .then(res => {
        fetch(res.default)
          .then(res => res.text())
          .then(res => setPost(res))
      })
      .catch(err => console.log(err))

    return () => { }
  }, [markDownURI]);


  useEffect(() => {
    if ("lesson-eth-9" === markDownURI) {
      console.log('inside markdown', markDownURI);
      return setLessonSubmission(true);
    }

    if ("lesson-eth-12" === markDownURI) {
      console.log('inside markdown', markDownURI);
      return setLessonSubmission(true);
    }

    if ("lesson-eth-13" === markDownURI) {
      console.log('inside markdown', markDownURI);
      return setLessonSubmission(true);
    }
    return setLessonSubmission(false);

  }, [nextButton, prevButton, markDownURI]);


  document.addEventListener('keydown', function (event) {
    //listen for right or left arrow keys
    if (event.keyCode === 39) {
      //console.log('right arrow key pressed');
      if (nextButton !== 0) {
        window.analytics.track('Lesson Next Button Clicked');
        return window.location.href = `${courseId}/lesson/${title}/${markDownString}${nextButton}/${nextButton}`;
      } else {
        return;
      }

    } else if (event.keyCode === 37) {
      //console.log('left arrow key pressed');
      if (prevButton !== 0) {
        window.analytics.track('Lesson Previous Button Clicked');
        return window.location.href = `${courseId}/lesson/${title}/${markDownString}${prevButton}/${prevButton}`;
      } else {
        return;
      }
    }
  });




  // console.log("nextButton", buttonState.nextButton)
  // console.log("prevButton", buttonState.prevButton)
  //course completion, add doc to collection, if doc exists dont show form, if doc doesnt exist show form
  //add to collection with user id and file name/url
  //on page load if collection exists dont show form, show message youve submitted form pending certification
  //reviewed: true or false, if true show you recieved and nft, if false show pending

  return (
    <div className="course-page">
      <Header />
      <main className="">
        <div className="flex">
          <div className="hidden h-screen sticky top-0 lg:block">
            <div className="sidebar min-h-screen w-64">
              <Sidebar />
            </div>
          </div>
          <div className="grid py-6 sm:px-8 sm:py-2 w-full">
            <div className="sticky top-0 pt-5 pb-3 bg-gray-900 z-50">
              <div className="">
                <ProgressBar submissions={submissions} courseId={courseId} user={user} />
              </div>
            </div>

            <div className="w-full  max-w-none rounded-b-md shadow-sm border border-gray-600 p-5 bg-white overflow-y-auto rounded-lg">
              <ReactMarkdown
                children={post}
                linkTarget="_blank"
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                className={"w-full prose prose-indigo max-w-none sm:p-5 overflow-y-auto prose-pre:p-0"}
                components={{
                  code({ node, bash, soldity, javascript, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '')
                    return !inline && match ? (
                      <div className="relative">
                        <CopyToClipboard text={children}>
                          <div className="flex justify-end absolute top-1 right-3">
                            <button className="inline-block hover:text-lime-600" aria-label="Copy to Clipboard">
                              <span><span className="invisible md:visible">Copy to Clipboard</span> <BiCopy className="inline-block" /></span>
                            </button>
                          </div>
                        </CopyToClipboard>
                        <SyntaxHighlighter
                          children={String(children).replace(/\n$/, '')}
                          style={atomDark}
                          language={match[1]}
                          PreTag="div"
                          showLineNumbers={true}
                          wrapLongLines={true}
                          {...props}
                        />
                      </div>
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    )
                  }
                }}
              />

              {/* start of pagnation */}
              {lessonSubmission && <ProjectSubmission markDownURI={markDownURI} />}

              <div className="bg-white px-4 pt-3 mt-10 flex items-center justify-between border-t border-gray-200 sm:px-6">
                <div className="flex-1 flex justify-end sm:justify-between sm:items-center">
                  <div className="hidden sm:block">

                  </div>
                  <div>
                    <div className="relative z-0 inline-flex rounded-md space-x-4" aria-label="Pagination">
                      <Link to={`/${courseId}/lesson/${title}/${markDownString}${prevButton}/${prevButton}`}
                        id="prev-button"
                        className={`relative inline-flex items-center px-4 py-2 border border-green-300 text-sm font-medium rounded-md text-white bg-green-300 hover:bg-green-200 ${prevButtonHide}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>Previous
                      </Link>
                      <Link to={`/${courseId}/lesson/${title}/${markDownString}${nextButton}/${nextButton}`}
                        id="next-button"
                        className={`relative inline-flex items-center px-4 py-2 border border-green-300 text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-400 ${nextButtonHide}`}>
                        Next <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                        </svg>
                      </Link>
                      {
                        <MarkCompleteButton markDownURI={markDownURI} user={user} setHideMarkComplete={setHideMarkComplete} />
                      }
                    </div>
                    <div id="chat-bot">
                      <button
                        className="fixed right-5 bottom-5 transition hover:scale-110"
                        onClick={toggle}
                      >
                        <img
                          width={160}
                          src="https://cadena.dev/wp-content/uploads/2023/09/ask-cadena-light.png"
                          alt="CadenaChat"
                        />
                      </button>

                      {isShown &&
                        <div className="fixed right-5 bottom-28 transition-transform duration-500">
                          <ChatBot width={"350px"} height={"500px"} />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              {/* end of pagnation */}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
