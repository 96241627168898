import { useEffect, useState } from 'react';
import { db } from '../firebase/config'
import { where, addDoc, query, collection, onSnapshot } from 'firebase/firestore'
import { serverTimestamp } from "firebase/firestore";
import toast from 'react-hot-toast';

export default function MarkCompleteButton(props) {
  const { user, markDownURI } = props;
  const [submitted, setSubmitted] = useState(false);
  const messages = ['Well done!', 'Keep going!', 'Great job!'];

  const notify = () => toast.custom(
    <div className="flex w-full max-w-sm mx-auto overflow-hidden rounded-lg shadow-md bg-gray-800">
      <div className="flex items-center justify-center w-12 bg-emerald-500">
        <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z" />
        </svg>
      </div>

      <div className="px-4 py-2 -mx-3">
        <div className="mx-3">
          <span className="font-semibold text-emerald-400">Success</span>
          <p className="text-sm text-gray-200">{`${messages[Math.floor(Math.random() * (messages.length))
          ]} +10 pts`}</p>
        </div>
      </div>
    </div>
  );


  function markLessonComplete() {
    if (user && user.uid && markDownURI) {
      addDoc(collection(db, "submissions"), {
        uid: user.uid,
        lessonId: markDownURI,
        timestamp: serverTimestamp()
      });
    }
    setSubmitted(true);
    notify();
  }
  useEffect(() => {
    const ref = query(collection(db, "submissions"),
      where('uid', '==', user.uid),
      where('lessonId', '==', markDownURI)
    );
    const unsub = onSnapshot(ref, snapshot => {
      let results = []
      if (!snapshot.empty) {
        snapshot.docs.forEach(doc => {
          results.push({ ...doc.data(), id: doc.id })
        })
        setSubmitted(true);
        window.analytics.track({
          lessonId: markDownURI,
          userId: user.uid,
          event: `Completed Lesson ${markDownURI}`,
        });
        console.log('results', results);
      } else {
        setSubmitted(false);
      }
    })
    return () => unsub()
  }, [markDownURI]);

  console.log('submitted', submitted);

  return (
    <button type="button"
      onClick={markLessonComplete}
      disabled={submitted}
      className={`has-tooltip relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50`}
    >
      {!submitted && (
        <span className='tooltip rounded shadow-lg p-3 bg-gray-900 text-gray-200 -mt-24'>Mark a lesson as completed to track your progress.</span>
      )}
      {submitted ? "Submitted ✨" : "Mark Lesson As Completed ✅"}
    </button>
  )
}