import { useState, useEffect } from 'react';
import { useSignup } from "../hooks/useSignup";
import { Link, useLocation } from 'react-router-dom';
import AuthButtons from "../components/AuthButtons";
import { usePostHog } from 'posthog-js/react';

import posthog from 'posthog-js'

export default function Signup() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [tosSigned, setTosSigned] = useState(false)
  const posthog = usePostHog()
  const { error, signup } = useSignup()
  let location = useLocation();
  let queryParams = new URLSearchParams(location.search);
  let referralSource = queryParams.get('ref'); // 'ref' is the query param key for the referral source

  console.log('referralSource', referralSource);

  const handleSubmit = (e) => {
    e.preventDefault()
    signup(email, password)
  }

  const handleChange = () => {
    setTosSigned(!tosSigned);
  };

  useEffect(() => {
    document.title = 'Sign up - Cadena';
    window.analytics.page(`Sign up - Cadena`);
    posthog.capture('$pageview');

    if (referralSource) {
      localStorage.setItem('referralSource', referralSource);
    }
  }, [location]);

  return (
    <div className="min-h-full flex items-center justify-center mt-6 py-0 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-gray-800 shadow-lg rounded-lg px-10 py-10">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="https://cadena.dev/wp-content/uploads/2021/12/cadena-logo@4x.png"
            alt="Cadena Logo"
          />
          <h2 className="mt-6 text-center text-3xl font-bold text-white">Sign up for an account</h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            or{' '}
            <Link
              className="font-medium text-indigo-600 hover:text-indigo-500"
              to="/login">
              Log in
            </Link>
          </p>
        </div>
        <form onSubmit={handleSubmit} className="m-0">
          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2">
              Email
            </label>
            <input
              required
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="border border-gray-600 shadow appearance-none rounded w-full py-2 px-3 bg-gray-800 text-gray-300 leading-tight focus:outline-none focus:shadow-outline"

            />

          </div>
          <div className="mb-6">
            <label className="block text-white text-sm font-bold mb-2">
              Password
            </label>
            <input
              required
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              className="border border-gray-600 shadow appearance-none rounded w-full py-2 px-3 bg-gray-800 text-gray-300 leading-tight focus:outline-none focus:shadow-outline"

            />
          </div>
          <div className="flex items-center mb-6">
            <div className="flex items-center h-5 justify-between">
              <input
                id="acceptTosPrivacy"
                name="acceptTosPrivacy"
                type="checkbox"
                checked={tosSigned}
                onChange={handleChange}
                className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" />
            </div>
            <div className="ml-3 text-sm justify-between w-full">
              <div className="font-medium text-gray-300 pl-3">I've read and accepted to our <a className="text-indigo-500" href="https://cadena.dev/terms-of-service/" target="_blank" rel="noreferrer" alt="Terms of Service">Terms of Service</a> and  <a className="text-indigo-500" href="https://cadena.dev/privacy-policy/" target="_blank" rel="noreferrer" alt="Privacy Policy">Privacy Policy</a></div>
            </div>
          </div>

          <button
            disabled={!tosSigned}
            className={`w-full flex items-center px-3 py-2 font-medium tracking-wide justify-center text-white capitalize transition-colors duration-200 transform bg-indigo-600 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring focus:ring-indigo-300 focus:ring-opacity-80 ${!tosSigned && 'opacity-50 cursor-not-allowed'}`}
          > Sign Up
          </button>
          {error && <p className="text-red-500 text-xs italic mt-3">{error}</p>}
        </form>
        <AuthButtons category={"Sign Up"} disabled={false} />
        <div className="font-medium text-xs text-gray-300 pl-3">By signing up with Google or Github you agree that you've read and accepted our <a className="text-indigo-500" href="https://incl.us/terms-of-service/" target="_blank" rel="noreferrer" alt="Terms of Service">Terms of Service</a> and  <a className="text-indigo-500" href="https://incl.us/privacy-policy/" target="_blank" rel="noreferrer" alt="Privacy Policy">Privacy Policy</a></div>
      </div>



    </div >
  )
}
