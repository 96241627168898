import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { db } from '../firebase/config'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { useUserMetaDataContext } from "../hooks/useAuthContext";

import * as Yup from 'yup';
import toast from 'react-hot-toast';

export default function ProjectSubmission({ markDownURI }) {
  const { user } = useUserMetaDataContext();
  const [saveState, setSaveState] = useState(false);

  //console.log('user', user.email);
  const notify = () => toast.custom(
    <div className="flex w-full max-w-sm mx-auto overflow-hidden rounded-lg shadow-md bg-gray-800">
      <div className="flex items-center justify-center w-12 bg-emerald-500">
        <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z" />
        </svg>
      </div>

      <div className="px-4 py-2 -mx-3">
        <div className="mx-3">
          <span className="font-semibold text-emerald-400">Success</span>
          <p className="text-sm text-gray-200">Project Submitted</p>
        </div>
      </div>
    </div>
  );

  return (
    <Formik
      initialValues={
        {
          submission: ''
        }
      }

      validationSchema={Yup.object({
        submission: Yup.string().required('Required')
      })}

      onSubmit={async (values) => {

        try {
          await addDoc(collection(db, 'mail'), {
            uid: user.uid,
            dateSubmitted: serverTimestamp(),
            to: 'team@cadena.dev',
            from: `mail@cadena.dev`,
            cc: `${user.email}`,
            message: {
              from: `${user.email}`,
              subject: 'Cadena Submission',
              text: `${user.email} ${values.submission} ${markDownURI}`,
              html: `<p> Email: ${user.email}</p><p>${values.submission}</p><p>Lesson: ${markDownURI}</p>`,
            }
          });
          setSaveState(true);
          window.analytics.track({
            userId: user.uid,
            event: `Lesson Submitted ${markDownURI}`,
          });
          notify();
        }
        catch (error) {
          console.log(error);
        }
      }}
    >
      {formik => (
        <Form className="sm:px-5">
          <div className="w-full mt-4">
            <label className="prose" htmlFor="submission">You can also use the form below to submit your projects.</label>
            <Field
              className="block w-full px-4 py-2 mt-2 rounded-tl-lg rounded-tr-lg bg-gray-900 text-gray-300 border-gray-600 focus:none"
              name="submission"
              component="textarea"
              placeholder="Github Project Links, Links to Your Live App, etc."
              rows="4"
            />
          </div>
          <div className="flex justify-end">
            <button disabled={saveState} type="submit" className="block w-full px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-800 hover:bg-gray-700 focus:outline-none focus:bg-gray-600 rounded-br-lg rounded-bl-lg">Submit Project</button>
          </div>
          <ErrorMessage name="submission">
            {msg => <span className={`text - red - 700`}>{msg}</span>}
          </ErrorMessage>
        </Form>
      )
      }
    </Formik>
  )
}