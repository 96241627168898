import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import NewPassword from '../components/NewPassword';
import EmailVerification from '../components/EmailVerification';

function useQuery() {
  const location = useLocation();
  return new URLSearchParams(location.search);
}

export default function ResetPassword() {
  const query = useQuery()
  const oobCode = query.get('oobCode')
  const mode = query.get('mode');
  console.log(query.get('mode'));
  console.log('oobCode', oobCode);
  console.log(query.get('continueUrl'));

  useEffect(() => {
    document.title = `${mode === "resetPassword" ? 'Reset Password' : 'Email Verification'} - Cadena`;
    window.analytics.page(`Reset Password - Cadena`);
  }, [mode]);

  return (
    <>{mode === "resetPassword" ? <NewPassword oobCode={oobCode} /> : <EmailVerification oobCode={oobCode} />}</>
  )
}
