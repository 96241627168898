import WalletConnect from "../components/WalletConnect";

export default function UserProgress({ user, profileExists, submissions, authIsReady, userWalletAddress }) {

  function tweetShare() {
    window.analytics.track({
      userId: user.uid,
      event: `Tweet to Invite`,
    });
  }

  console.log('submissions 1', submissions.length);
  // console.log('authIsReady', authIsReady);
  // console.log('profile exists', profileExists);
  // console.log('userData', user)
  // console.log('submissions 2', submissions.length);

  return (
    <div className="col-1 overflow-hidden rounded-lg  mb-10 md:mb-0 relative">

      <h2 className="text-3xl font-bold text-white mb-2">
        📝 Action Items
      </h2>

      {authIsReady && < WalletConnect user={user} authIsReady={authIsReady} userWalletAddress={userWalletAddress} />}
      {authIsReady && (
        <div className="action-items">
          <a
            className="w-full flex justify-between items-center my-auto px-8 py-4 mx-auto rounded-lg shadow-md bg-gray-800 mt-0 mb-2"
            href="/profile"
          >
            <p className="text-xl font-bold text-white hover:text-gray-200 text-left pr-5">{profileExists ? "Profile Completed" : "Complete Your Profile"}</p>
            <div className="check text-xl">
              <span className={!profileExists ? "filter grayscale" : undefined}>✅</span>
            </div>
          </a>

          <div
            className="w-full flex justify-between items-center my-auto px-8 py-4 mx-auto rounded-lg shadow-md bg-gray-800 mt-0 mb-2"
          >
            <p className="text-xl font-bold text-white hover:text-gray-200 text-left pr-5">{submissions.length > 0 ? "Course Started" : "Get Started with a Course"}</p>
            <div className="check text-xl">
              <span className={submissions.length === 0 ? "filter grayscale" : undefined}>✅</span>
            </div>
          </div>
          <div
            className="w-full flex justify-between items-center my-auto px-8 py-4 mx-auto rounded-lg shadow-md bg-gray-800 mt-0 mb-2"
          >
            <p className="text-xl font-bold text-white hover:text-gray-200 text-left pr-5">Points Earned: {submissions.length * 10}+</p>
            <div className="check text-xl">
              <span className={submissions.length === 0 ? "filter grayscale" : undefined}>✅</span>
            </div>
          </div>

          <a
            className="w-full flex justify-between items-center my-auto px-8 py-4 mx-auto rounded-lg shadow-md bg-gray-800 mt-0 mb-2"
            href="https://discord.gg/UQayXxzazc" target="_blank" rel="noopener noreferrer"
          >
            <p className="text-xl font-bold text-white hover:text-gray-200 text-left pr-5">Join Our Discord</p>
            <div className="check text-xl">
              <span className={!profileExists ? "filter grayscale" : undefined}>✅</span>
            </div>
          </a>
          <a
            className="w-full flex justify-between items-center my-auto px-8 py-4 mx-auto rounded-lg shadow-md bg-gray-800 mt-0"
            href="https://twitter.com/intent/tweet?text=Join%20me%20to%20learn%20%23gpt%20%26%20%23blockchain%20development%20with%20%40cadenadev%0A%0AGet%20started%20at%3A%20https%3A%2F%2Fcadena.dev%2F" target="_blank" rel="noopener noreferrer"
            onClick={tweetShare}
          >
            <p className="text-xl font-bold text-white hover:text-gray-200 text-left pr-5">Invite Friends on Twitter</p>
            <div className="check text-xl">
              <span className={!profileExists ? "filter grayscale" : undefined}>✅</span>
            </div>
          </a>

        </div>
      )}
    </div>
  )
}