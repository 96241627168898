import { ethers } from "ethers";
import { useState, useEffect } from "react";
import { db } from '../firebase/config'
import { addDoc, collection } from 'firebase/firestore';

export default function WalletConnect({ user, authIsReady, userWalletAddress }) {
  //const { user, profileExists, submissions, authIsReady, userWalletAddress } = useUserMetaDataContext();
  const [walletConnect, setWalletConnect] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [error, setError] = useState(null);

  async function connectWallet() {
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
        const account = accounts[0];
        setWalletAddress(account);
        console.log('address connect wallet call', account);
        //saveWalletAddress();
        if (authIsReady && user.uid && userWalletAddress === null) {
          console.log("Wallet Address Saved to db 1");
          addDoc(collection(db, "walletAddresses"), {
            uid: user.uid,
            walletAddress: account
          });
          console.log("Wallet Address Saved to db 2");
          setWalletConnect(true);
          window.analytics.track({
            userId: user.uid,
            event: 'Wallet Connected'
          });
        }
      } else {
        setWalletConnect(false);
        setError("Install a MetaMask Wallet.");
        console.log("No wallet detected.");
      }
    } catch (error) {
      setError("No wallet found");
      console.log(error);
    }
  }
  useEffect(() => {
    if (userWalletAddress) {
      setWalletConnect(true);
      setWalletAddress(userWalletAddress[0].walletAddress);
      console.log('wallet connect', userWalletAddress[0].walletAddress);
    }
    //connectWallet();
  }, [userWalletAddress]);


  return (
    <div className="w-full flex justify-start items-center my-auto px-8 mx-auto rounded-lg shadow-md bg-gray-800 mt-0 mb-2">
      <button
        className={`relative inline-flex items-center px-4 py-2   text-sm font-medium rounded-md text-white ${walletConnect ? "bg-indigo-500" : "bg-red-500"}`}
        type="button"
        disabled={walletConnect}
        onClick={connectWallet}
      >
        {walletConnect ? "Wallet Connected 🔒" : "Connect Wallet 🔑"}
      </button>
      <div className="blurb px-8 py-6 text-white">{walletAddress ? `${walletAddress.substr(0, 24)}...` : 'Wallet Address'}</div>
      {error && <div className="text-red-500 mt-2">{error}</div>}
    </div >
  )
}

/**
 * 1. connect to waller
 * 2. save address to db with user id
 *  */