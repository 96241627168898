import { useEffect, useState } from 'react';
import { usePostHog } from 'posthog-js/react'
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ChatBot from "../components/ChatBot";
import { useUserMetaDataContext } from "../hooks/useAuthContext";

export default function AskCadena() {
  const posthog = usePostHog()
  const { user } = useUserMetaDataContext();

  useEffect(() => {
    document.title = 'Ask Cadena - Cadena';
    window.analytics.page(`Ask Cadena - Cadena`);
    window.analytics.identify(`${user.uid}`, {
      email: `${user.email}`,
    });
    posthog.identify(`${user.uid}`)
    posthog.people.set({ email: `${user.email}` })

  }, [user]);



  //console.log('courseList outisde', courseList);
  return (
    <div className="dashboard-page">
      <Header />
      <main className="">
        <div className="flex">
          <div className="hidden h-screen sticky top-0 lg:block">
            <div className="sidebar min-h-screen w-64">
              <Sidebar />
            </div>
          </div>
          <div className="grid md:grid-cols-2 md:gap-10 p-10">
            <div id="desktop" className="hidden md:block">
              <ChatBot width={"1100px"} height={"750px"} />
            </div>
            <div id="mobile" className="block md:hidden">
              <ChatBot width={"100%"} height={"650px"} />
            </div>
          </div>

        </div>
      </main>
    </div>
  );
}