import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react'

import { LessonContextProvider } from "../context/LessonContext";
import { useUserMetaDataContext } from "../hooks/useAuthContext";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Lesson from "../components/Lesson";
import ProgressBar from "../components/ProgressBar";
import ChatBot from "../components/ChatBot";


export default function CourseSection() {
  const { title, id: courseId } = useParams();
  const { submissions, user } = useUserMetaDataContext();
  const posthog = usePostHog()
  const [isShown, setIsShown] = useState(false);


  const toggle = () => {
    setIsShown(!isShown);
    posthog.capture('ChatBot Clicked');
  };

  //refactor this out to a hook
  useEffect(() => {
    let newTitle = title.replace(/-/g, ' ');
    let finalTitle = newTitle.replace(/(^[^\s-]+)|(\s+[^\s-]+)/g, function (letter) {
      return letter.toUpperCase();
    });
    document.title = `${finalTitle} - Lessons`;
    window.analytics.page(`${finalTitle} - Lessons`);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

  }, [title]);

  return (
    <div className="course-page">
      <Header />
      <main className="">
        <div className="flex">
          <div className="hidden h-screen sticky top-0 lg:block">
            <div className="sidebar min-h-screen w-64">
              <Sidebar />
            </div>
          </div>
          <div className="p-10 w-full">
            <ProgressBar submissions={submissions} courseId={courseId} user={user} />
            <LessonContextProvider>
              <Lesson />
            </LessonContextProvider>
          </div>
          <div id="chat-bot">
            <button
              className="fixed right-5 bottom-5 transition hover:scale-110"
              onClick={toggle}
            >
              <img
                width={160}
                src="https://cadena.dev/wp-content/uploads/2023/09/cadena-chat.png"
                alt="CadenaChat"
              />
            </button>

            {isShown &&
              <div className="fixed right-5 bottom-28 transition-transform duration-500">
                <ChatBot width={"350px"} height={"500px"} />
              </div>
            }
          </div>
        </div>
      </main>
    </div>
  );
}
